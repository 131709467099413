@import "_import.scss";
@import "import._base";

@import "_import.button.scss";

.title{
    color: $white;
    font-weight: 700;
    font-size: 30px;
    text-align: center;
}

.base-area{
    background-color: $white-blue;
    border-radius: 5px;
    width: 800px;
    margin: 10px auto 20px auto;
    padding: 0 0 30px 0;

    .base-area-element{
        width: 400px;
        margin: 0 auto 0 auto;
        padding: 10px 0 0 0;

        p{
            //font-weight: 700;
            font-size: 18px;
            text-align: center;
            padding: 10px 0 0 0;
            margin: 0;
        }
    
        .textbox{
            display: flex;
            margin: 20px auto 20px auto;
            width: 250px;
    
            svg{
                width: 30px;
                height: 30px;
                fill: $black-blue;
            }
    
            input{
                margin: 0 0 0 10px;
            }
    
        }
    
        .btn-area{
            margin: 10px auto 10px auto;
            width: 165px;
    
            button{
                @include button($color: $white, $background: $right-blue);
                border: 0;
                position: relative;
            }
        
            button:hover{
                background-color:  #0c91d8;
            }
        }
    
        .password-forgot{
            //width: 182px;
            text-align: center;
            margin: 0 auto 0 auto;
    
            a{
                color: $black-blue;
                cursor: pointer;
                
            }
        }

        .login-divider{
            font-size: 12px;
            margin: 24px auto 16px;
            text-align: center;
            width: 272px;
            position: relative;
        }

        .login-divider::before{
            content: '';
            display: block;
            width: 88px;
            height: 1px;
            position: absolute;
            top: 50%;
            left: 0;
            background-color: $gray;
        }

        .login-divider::after{
            content: '';
            display: block;
            width: 88px;
            height: 1px;
            position: absolute;
            top: 50%;
            right: 0;
            background-color: $gray;
        }

        .sns-area{
            margin: 35px auto 0 auto;
            width: 209px;

            .google{
                background-color: $white;
                color: $gray;
                font-weight: 700;
                border: 2px solid #b6b6b6;
                border-radius: 5px;
                margin: 0 auto 0 auto;
                padding: 10px 28px 10px 10px;
                text-decoration: none;
                cursor: pointer;
               

                img{
                    width: 30px;
                    height: 30px;
                    margin: 0 10px 0 0;
                }
            }

            .google:hover{
                border: 2px solid $gray;
            }

            .facebook{
                background-color: $white;
                color: $black-blue;
                font-weight: 700;
                border: 2px solid #b6b6b6;
                border-radius: 5px;
                margin: 0 auto 10px auto;
                padding: 10px;
                text-decoration: none;
                cursor: pointer;

                img{
                    width: 30px;
                    height: 30px;
                    margin: 0 10px 0 0;
                }
            }

            .facebook:hover{
                border: 2px solid $black-blue;
            }

        }


    }
}