@import "_import.scss";
@import "import._button";




.check__area .button__area{
    .returnBtn{
        @include button($color: $white, $background: $right-blue);
        @include left-side-button;
    }
    .restart{
        @include right-center-button;
    }
    .later{
        @include right-side-button;
    }
    @include button-area-common;
}


@mixin exercise-btn-area {
    border-top: 1px solid $gray;
    button {
        @include button;
    }

    button:hover {
        background-color: $white-blue;
    }

    button:focus {
        @include button__focus;
    }

    .restartBtn {
        @include right-side-button;
    }

    .text-btn {
        @include button($color: $white, $background: $green, $border:$green);
        @include right-center-button;
    }

    .text-btn:hover {
        background-color: #34a7b7;
    }

    .run-btn {
        @include left-side-button;
    }

    .check_answer_btn {
        @include button($color: $white, $background: $right-blue);
        @include left-center-button;
    }

    .check_answer_btn:hover {
        background-color: #0c91d8;
    }
    @include button-area-common;
}



/*-------- 答え合わせ（正解）画面のボタン --------*/
@mixin check-answer-correct-btn {
    display: flex;
    position: absolute;
    background-color: $white;
    border-top: 1px solid $gray;
    height: 55px;
    position: relative;
    position: -webkit-sticky; /* Safari用 */
    position: sticky; /* 要素をスクロールに応じて固定 */
    /*top: 91.5% /* 縦スクロールに追従 */
    bottom: 0%;
    z-index: 100; /* z-indexで最前面に持ってくる */
    // margin-left: 10px;
    // margin-right: 10px;

    // form{
    //     height: 40px;
    //     width: 150px;
    //     margin: 0;
    // }

    button {
        /*background-color: $white;*/
        @include button;
        margin: 7px;
    }

    button:hover {
        background-color: $white-blue;
    }

    // button:active{
    // 	@include button__active;
    // }

    button:focus {
        @include button__focus;
    }

    .next-element-btn {
        @include button($color: $white, $background: $right-blue);
        position: absolute;
        right: 1%;
    }

    .next-element-btn:hover {
        background-color: #0c91d8;
    }

    .returnBtn {
        @include button($color: $white, $background: $right-blue);
    }

    .returnBtn:hover {
        background-color: #0c91d8;
    }

    .restartBtn {
        position: absolute;
        right: 35%;
    }

    .later {
        position: absolute;
        right: 1%;
    }
}

/*-------- 答え合わせ（不正解）画面のボタン --------*/
@mixin check-answer-incorrect-btn {
    background-color: $white;
    border-top: 1px solid $gray;
    /*height: 50px;*/
    position: relative;
    position: absolute;
    position: -webkit-sticky; /* Safari用 */
    position: sticky; /* 要素をスクロールに応じて固定 */
    /*top: 91.5% /* 縦スクロールに追従 */
    bottom: 0%;
    z-index: 100; /* z-indexで最前面に持ってくる */

    button {
        /*background-color: $white;*/
        @include button;
        //margin: 5px;
    }

    button:hover {
        background-color: $white-blue;
    }

    // button:active{
    // 	@include button__active;
    // }

    button:focus {
        @include button__focus;
    }

    .returnBtn {
        @include button($color: $white, $background: $right-blue);
        position: relative;
        left: 1%;
    }

    .returnBtn:hover {
        background-color: #0c91d8;
    }

    .restartBtn {
        position: absolute;
        right: 35%;
    }

    .later {
        position: absolute;
        right: 1%;
    }
}

