@import "_import";

.question {
	background-color: $white;
	max-height      : 500px;
	min-width       : 150px;
	height          : $questionHeight;
	overflow        : auto;
	width           : 100%;
	margin          : 0px;
	position        : absolute;
	top             : 0;
	box-shadow      : 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius   : 5px;
	font-size       : 15px;
	position        : relative;
	padding         : 5px;
	padding-top     : 10px;


	div {
		width           : 95%;
		height          : auto;
		border          : 1px solid $gray;
		background-color: $white;
		border-radius   : 0;
	}

}