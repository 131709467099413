@import "_import.scss";

//@import "import.button";

@mixin button($color: $right-blue, $border: $right-blue, $background: #ffffff, $border-bottom: $right-blue, $hover-color:$right-blue) {
    display: inline-block;
    padding: 0.3em 1em;
    margin: 7px;
    text-decoration: none;
    color: $color;
    border: solid 2px $border;
    font-size: 15px;
    font-weight: 700;
    border-radius: 3px;
    transition: 0.4s;
    background: $background;
    height: 40px;
    width: 150px;
    outline: 0;
    text-align: center;
    &:hover{
        background-color: $hover-color;
    }
}

@mixin forward-button {
    @include button($color: $white, $background: $right-blue, $hover-color:#0c91d8);
   
}
@mixin button-area-common{
    background-color: $white;
    position: fixed;
    bottom: $footerHeight;
    z-index: 100; /* z-indexで最前面に持ってくる */
    width:100%;
    height:$footerButtonAreaHeight;
}

@mixin button__focus {
    outline: 0;
}
@mixin left-side-button{
    position: absolute;
    left: 1%;
}
@mixin left-center-button{
    position: absolute;
    left: 37%;
}
@mixin right-center-button{
    position: absolute;
    right: 35%;
}
@mixin right-side-button{
    position: absolute;
    right: 1%;
}

/*---ボタン押し込みデザイン---*/
@mixin button__active($border-bottom: $right-blue) {

    border-bottom: 2px solid $border-bottom;
}

/*---クリックしたときに出る黒い線を消す---*/
@mixin button__focus {
    outline: 0;
}

@mixin buttonHover($background-hover: $white-blue, $color: $white-blue) {
    background: $background-hover;
    color: $color;
}

@mixin button-area-common{
    background-color: $white;
    position: fixed;
    bottom: $footerHeight;
    z-index: 100; /* z-indexで最前面に持ってくる */
    width:100%;
    height:$footerButtonAreaHeight;
}

/*----------- Scratchの１人で解く・穴埋め・演習 ---------*/
@mixin exercise-btn-area-scratch {
    position: absolute;
    background-color: $white;
    border-top: 1px solid $gray;
    height: 55px;
    position: relative;
    position: -webkit-sticky; /* Safari用 */
    /*top: 91.5% /* 縦スクロールに追従 */
    bottom: 0%;
    z-index: 100; /* z-indexで最前面に持ってくる */


    button {
        /*background-color: $white;*/
        @include button;
        margin: 7px;
    }

    button:hover {
        background-color: $white-blue;
    }


    button:focus {
        @include button__focus;
    }

    .text-btn {
        @include button($color: $white, $background: $green);
        /*margin-left: 300px;*/
        position: absolute;
        left: 1%;
        border: 0;
    }

    .text-btn:hover {
        background-color: #34a7b7;
    }

    .check_answer_btn {
        @include button($color: $white, $background: $right-blue);
        position: absolute;
        right: 35%;
    }

    .check_answer_btn:hover {
        background-color: #0c91d8;
    }

}