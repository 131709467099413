@import "_import";
@import "import._question";

$editor-area-width: 30%;

#normal-contents{
    > .area-title{
        color: $white;
        text-align: center;
        font-size: 45px;
        font-weight: bold;
        position: relative;
        height:$areaHeight;
    
        i{
            color:  rgba($white, 0.5);
            font-size: 2em;
        }
    
        svg{
            width: 70px;
            height: 70px;
    
            .cls-1{
                fill:  rgba($white, 0.5);
            }
            
        }
    
        div{
            color: $white;
            text-align: center;
            font-size: 35px;
            font-weight: bold;
            width: 100%;
            margin: 5px 0 20px 0;
        }
    
    }
}

#normal-contents {
    .program__area {
        .main-area {
            height: calc(100% - 120px);
        }
    }
}

#element-contents {
    .program__area {
        .main-area {
            height: calc(100% - #{$questionHeight} - 120px);
        }
    }
}

@mixin left-right-area {
    flex-wrap: wrap;
    justify-content: space-around;
    flex-basis: auto;
    width: 50%;
    background-color: $white-blue;
    border-radius: 5px;
    margin: 0px 5px 0 10px;
    position: relative;
    width: 50%;
    overflow: hidden;
}

.base-area-single {
    background-color: $white-blue;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 10px auto;
    margin-bottom: 0px;
    padding: 5px;
    max-width: 1200px;
}
.base-area {
    background-color: $white-blue;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin: 10px 5px 0 5px;
    margin-bottom: 0px;
    padding: 5px;

    .top-area {
        display: flex;
        margin: 0;
        .left-area,
        .right-area {
            @include left-right-area;
        }
        .right-area{
            position: relative; //vertical-middleを上下中央にするため
            .later-button-area{
                margin-top: 20px;
            }
            .vertical-middle{
                position: absolute;
                width: 100%;
                top: 50%;
                left: 0%;
                transform: translateY(-50%);
            }
        }
        .question {
            // @include question;
            background-color: $white;
            max-height: 500px;
            min-width: 150px;
            height: 150px;
            overflow: auto;
            width: 100%;
            margin: 0px;
            position: absolute;
            top: 0;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            font-size: 15px;
            position: relative;
            padding: 5px;
            padding-top: 10px;
        }
        .ui-resizable-handle.ui-resizable-s {
            position: absolute;
            width: 100%;
            height: 15px;
            background: url(/static/dist/img/hsizegrip.png) center center no-repeat #c1d0ea;
            cursor: row-resize;
        }
        .ui-resizable-handle.ui-resizable-s.dragging{
            position: fixed;
            top:0;
            height: 100vh;
            background-color: transparent;
            background-image: none;

        }

    }
    .middle-area{
        display: flex;
//        height: calc(100% - #{$questionHeight} - 120px);
        margin: 0;

        .left-area,
        .right-area {
            @include left-right-area;
        }
    }
    .main-area {
        display: flex;
        height: calc(100% - #{$questionHeight} - 120px);
        margin: 0;
        padding-top: 10px;

        &.sandbox{
            height: calc(100% - 100px);
        }

        &.exam{
            height: calc(100% - #{$questionHeight} - 20px);
        }

        .left-area,
        .right-area {
            @include left-right-area;
        }

        .editer-area {
            width: $editor-area-width;
            //        width: $editor-area-width;
            //        min-width: 300px;
            height: 100%;
            padding: 0 0;
            margin: 0 0 0 10px;

            .ui-resizable-handle.ui-resizable-e {
                width: 15px;
                background: url(/static/dist/img/vsizegrip.png) center center no-repeat #c1d0ea;
                cursor: col-resize;
            }
            .editer-area-element {
                height: 100%;
                background-color: $white;
                border-radius: 5px;
                width: 100%;
                box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
                // margin-left: auto;
                // margin-right: auto;
            }
        }
        .editer-area.SQL {
            flex: 0 0 auto;
            width:30%;
        }
        .editer-area.JAVA {
            flex: 0 0 auto;
            width:70%;
        }
        .run-area {
            width: 100% - $editor-area-width;
            margin: 0 10px 5px 5px;
            position: sticky;
            //transform:rotate(180deg);
            //max-width: 50%;
            overflow: auto;
            height: 100%;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        }
        .run-area{
            flex: 1;
        }

        .main-area-element{
            width: 100%;
            height: 100%;
        }
    }
}
//リサイズ可能な場合はマージン無し。リサイズできない場合はマージンあり（確認画面など）
.top-area:not(.ui-resizable){
    margin-bottom: 20px;
}

.list-area{
    background-color: $white-blue;
//    width: 1150px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    padding: 20px;
    padding-bottom: 0;
    border-radius: 5px;
    .title{
        display: flex;
        background-color: $black-blue;
        color: $white;
        border-radius: 5px;
        padding: 5px;
        width: 100%;
    }
    /*----- 一覧エリア -----*/
    .question-list-area{
        display: flex;
        background-color: $white;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        font-size: 18px;
        font-weight: 700;
        padding: 5px;
        margin-top: 5px;
        margin-top: 10px;
        cursor: pointer;
    }
    .question-list-area:hover{
        background-color: $white-blue;
    }
}

.current-question {
	color: $white;
	font-weight: 700;
	font-size: 20px;
}