.levelProgressBar {
    color: white;
    width: 100%;
    height: 70px;
//    right: 1%;
    //margin-right: 5%;
//    margin-left: 5%;
//    background-color: $white-blue;
    position: absolute;

    .progress {
        margin: 10px;
        max-width: 500px;
        margin-top: 30px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        background-color: #d5d5d5;
        height: 25px;

        p{
            color: $black-blue;
            font-weight: bold;
            margin-top: 5px;
            width: 100%;
            font-size: 1.5em;
            margin-bottom: 5px;
            margin-left: 10px;
            position: absolute;
            width: 500px;
            top: 0;
            
            svg{
                width: 30px;
                height: 30px;
                position: absolute;
                right: 60px;
                padding-bottom: 5px;

                .cls-1{
                    fill: #3EC1BD;
                }
            }
            
            .level{
                position: absolute;
                
            }

            .ex{
                position: absolute;
                right: 5%;
            }
        }

        #dynamic{
            font-size: 18px;
            line-height: 30px;
        }
    }

    .progress-bar-success{
        background-color: #3EC1BD;
        // #5cb85c
    }
}
.progress-bar{
    height: 100%;
    color: #fff;
    text-align: center;
    transition: width .6s ease;
}
.progress-bar.active{
    animation: progress-bar-stripes 2s linear infinite;
}







.progressbar {
    color: white;
    width: 50%;
    height: 70px;
    right: 1%;
    //margin-right: 5%;
    margin-left: 5%;
    background-color: $white-blue;

    .progress {
        margin: 10px;
        max-width: 500px;
        margin-top: 30px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        background-color: #d5d5d5;
        height: 20px;

        p{
            color: $black-blue;
            font-weight: bold;
            margin-top: 5px;
            position: relative;
            width: 100%;
            font-size: 1.5em;
            margin-bottom: 5px;
            margin-left: 10px;
            position: absolute;
//            max-width: 600px;
//            min-width: 500px;
//            width: 400px;
            top: 0;
            
            svg{
                width: 30px;
                height: 30px;
                position: absolute;
                right: 15%;
                padding-bottom: 5px;

                .cls-1{
                    fill: #3EC1BD;
                }
            }
            
            .level{
                position: absolute;
                
            }

            .ex{
                position: absolute;
                right: 5%;
            }
        }

        #dynamic{
            font-size: 18px;
            line-height: 30px;
        }
    }

    .progress-bar-success{
        background-color: #3EC1BD;
        // #5cb85c
    }
}
.progress-bar{
    height: 100%;
    color: #fff;
    text-align: center;
    transition: width .6s ease;
}
.progress-bar.active{
    animation: progress-bar-stripes 2s linear infinite;
}


