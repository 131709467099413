@import "_import";
@import "import._base-area";
@import "_destyle.scss";
@import "import._progressbar";

body {
        font-size: 14px;
        min-width: 1000px;
        background-color: $body-color;
        font-family: "メイリオ", "Arial", YuGothic, "Yu Gothic", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3",
                Meiryo, "ＭＳ ゴシック", sans-serif;
}
a {
        &:hover,
        &:focus {
                color: $link-hover-color;
                text-decoration: underline;
        }
}
button {
        text-align: center;
}
input[type="text"],input[type="password"]{
        background-color: $white;
        border: $gray 1px solid;
}
input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px $white inset;
}

#contents{
        padding-bottom:60px;
}
.list-contents{
        padding-bottom:60px;
        overflow-y: auto;
}

#wrapper {
        //width: 100%;
        height: 100vh;
        min-height: 100vh;
        position: relative;
        padding-bottom: 30px;
        box-sizing: border-box; /*←全て含めてmin-height:100vhに*/
        padding-left: 0;
        padding-right: 0;
}

#wrapper-element,
#wrapper-normal {
        //width: 100%;
        height: calc(100vh - 50px);
        min-height: calc(100vh - 50px);
        position: relative;
        //    padding-bottom: 100px;
        box-sizing: border-box;
        padding-left: 0;
        padding-right: 0;
}
#contents{
        margin-top:5px;
}

.btn {
        display: inline-block;
        margin-bottom: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        padding: 6px 12px;
        font-size: 14px;
        line-height: 1.42857143;
        border-radius: 4px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
}


/*--------- footer ---------*/
footer {
        width: 100%;
        height: $footerHeight;
        position: fixed;
        bottom: 0;
        background-color: $footer-color;
        color: $white;
        z-index: 100;

        .infomation-area {
                height: 100%;
                margin: auto;
                vertical-align: middle;
                width: 300px;
                padding-top: 5px;
        }

        a {
                text-decoration: none;
                color: $white;
        }
}
