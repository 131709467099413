$white: #ffffff;
$gray: #707070;
$right-blue: #0ca5e6;
$blue: #0a65c2;
$title-blue: #0059b3;
$yellow: #fff785;
$background-color: #edf9ff;
$black-blue: #0B41A0;
$white-blue: #e3eefa;
$body-color: #1c80e7;
$red: #ff7474;
$pink: #ffd8f2;
$green: #3ec1bd;
$hover-green: #34a7b7;
$hover-blue: #0c91d8;
$link-hover-color:#23527c;
$sub-title-color:$black-blue;
$sub-title-disabled-color: $gray;

$footer-color:#636363;

$footerHeight: 40px;
$buttonAreaHeight: 50px;
$areaHeight: 40px;
$questionHeight:150px;
$footerButtonAreaHeight:50px;
$mainTitleHeight:24px;




//C:\Users\iterative\Documents\TRYCODE\root\student\src\main\resources\static


/*レスポンシブデザイン*/
$breakpoints: (
	l: "only screen and (min-width: 991px)",
	xl: "only screen and (min-width: 1281px)",
);

@mixin media($breakpoint) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}
.base-area .top-area .question {
    font-size: 22.5px !important;
	min-height: 100%;
    overflow: initial;
}

table.resultSqlTable th,td {
	font-size: 24px!important;
	}

.ace_editor.ace_autocomplete.ace-eclipse {
    font-size: 20px!important;
	width:560px;
}
.ace_active-line{
	height: 80px;
}

@media screen and (max-height: 3850px){
	#virtual-keyboard-target_keyboard {
    top: 430px!important;
}
#passwordResetTitle,#mailAdd,#telNum
{
	font-size: 20px!important;	
}
#mailAddress,#telNo{
	width: 70%!important;
}
div[id^="ace"] {
  font-size: 24px!important;
}
}
@media screen and (max-height: 930px) {
div[id^="ace"] {
  font-size: 18px!important;
}
table.resultSqlTable th,td {
	font-size: 14px!important;
	}
pre.sourceContent,.answerCode-area  {
	font-size: 13px!important;
}

   .run-area .run-result .description,.base-area .top-area .question{
	font-size: 13.5px!important;
}
 div .run-area .run-result{
	font-size: 14px!important;
}
.ui-helper-reset{
	font-size: 70%!important;
}
#addTabTab {
    height: 22px!important;
}
.star span {
	font-size: 8px !important;
    height: 25px !important;
    width: auto;
}
.top-area .later-btn-area button{
	font-size: 8px !important;
    width: 70px !important;
    height: 23px;
}
#timerProgressBar {
    width: 65%!important;
}
.star,.later-btn-area{
	display: inline-block!important;
}
.later-btn-area{
	float: right!important;
}
.header-area .logo img {
    width: 110px!important;
    height: 30px!important;
}
.header-area .progressbar .progress .next-lebel-point-style,.header-area .progressbar .level,.element__name,#header-level-bar {
	font-size: 9px !important;
}

.header-area .progressbar .curriculum-name{
	width: auto!important;
	font-size: 10px!important;
} 
.header-area .progressbar svg {
    width: 25px!important;
    height: 25px!important;
}
.header-area .progressbar {
	height: 32px  !important;
}
.header-area .progressbar .progress {
	margin-top: 16px!important;
}
#header-level-bar{
	height: auto;	
}
.area{
	height: auto;	
}
.area #breadcrumbs-two{
	height: 28px!important;
}
.area #breadcrumbs-two a .element__name {
	margin-top: 11px!important;
}
.editer-area-element .editor-title,.run-area-element .run-result-title {
	font-size: 14px!important;
	height: 18px!important;
}
.area #breadcrumbs-two a {
	padding: 0.8px 8px !important;
}

.header-area .progressbar .progress{
	height: fit-content;	
}
.header-area .nav-link svg, .header-area .nav-link:link svg, .header-area .nav-link:visited svg, .header-area .nav-link:active svg, .header-area .submenu-link svg, .header-area .submenu-link:link svg, .header-area .submenu-link:visited svg, .header-area .submenu-link:active svg{
    width: 19px  !important;
    height: 33px!important;	
}
.header-area .nav-link, .header-area .nav-link:link, .header-area .nav-link:visited, .header-area .nav-link:active {
span{
	
	font-size: 10px!important;
}	
}
.header-area {
    height: 33px;
}


.base-area .main-area {    
height: calc(100% - 12px - 120px);

	&.sandbox{
		height: calc(100% - 50px);
	}

	&.exam{
		height: calc(100% - 12px - 120px);
	}

}
.button__area{
	.restartBtn,.text-btn,.returnBtn{
	font-size: 10px;
    height: 28px;
    width: 110px;
	}
}
.button__area .executeBtn{
	font-size: 10px!important;
    height: 28px!important;
    width: 110px!important;
}
.button__area .check_answer_btn {
	font-size: 10px!important;
    height: 28px!important;
    width: 110px!important;
}
.infomation-area{
	font-size: 10px;
}
.ace_editor.ace_autocomplete.ace-eclipse {
    font-size: 13px!important;
}

.ace_active-line {
    height: 18px!important;
}

.button__area{
	bottom: 23px;
	height: 43px;
}
footer{
	height: 30px!important;
}
.list-area
	.unit-area
		.unit span{
			font-size: 16px!important;
		}
 		.unit img{
			width: 45px!important;
		    height: 45px!important;
		}
	.question-list-area {
		font-size: 12px!important;
		}
 	.button__area {
		left: 0px!important;
		.first-question-btn,.next-element-btn {
			height: 28px!important;
			font-size: 12px!important;
	}

}
.baseArea button,.base-area-single button,.next-question-btn,.start {
	font-size: 10px!important;
	height: 28px!important;
}
.baseArea .selectAnswer-area
 .select-area ul li .radio-inline__label .select-text{
		font-size: 12px!important;
	}
 .select-area ul li
	.select-text{
		font-size: 12px!important;
	} 
	.select-name{
		max-width: 30px!important;
	    max-height: 30px!important;
	}
 .question-area {
	font-size: 14px!important;
}
	.result .result-area 
		.result-text{
			font-size: 1em!important;
		}
		.result-img img{
		width: 60px!important;
		height: 20px!important;
	}
.medal{
	display: inline-block!important;
}
 

.result span{
	font-size: 20px!important;
}
.result img{
	height: 32px !important;
    width: 40px !important;
}	
.area #breadcrumbs-two a .medal img{
	width: 20px!important;
    height: 20px!important;
}	
.answerCode{
	font-size: 12px!important;
}
table#rightSqlResultTable,table#userSqlResultTable{
.different,.same{
	font-size: 10px;
	}	
} 
span.level,.levelProgressBar .progress #dynamic  {
    font-size: 12px;
}
.levelProgressBar .progress{
	p {
		font-size: 1.3em;
	}
	svg {
	width: 20px;
    height: 34px;
}
} 

.custom,.curriculumTitle {
    font-size: 20px!important;
}
#typing-result-area{
	font-size: 12px;
}

 .menu__item__link{
 .unit__name{
	font-size: 16px;
}
 .madal__get__count .chart-area {
    width: 120px;
    height: 180px;
    top: 10px;
    left: -10px;
}}
.menu__item__link 
	{
	height:105px;
	}
.answerCode-area{
		font-size: 10px;
	}
.header-area .nav-item {
	height: 90%;
}

.base-area .input-area dl .optIn input {
    width: 4%;
    height: 12px;
}
.base-area .input-area dl dt {
    font-size: 12px;
}
.background-area .rule-area .back-btn-area input {
	height: 35px!important;
}
.scroll-area{
	font-size: 10px;
}
.base-area .element-area .btn-area .change-btn {
	height: 35px!important;
}
.base-area .btn-area
	{ 
		input{
		height: 35px!important;
	}
}
}

@media screen and (max-height: 930px){
	#virtual-keyboard-target_keyboard {
    top: 390px!important;
}
}
@media screen and (max-height: 780px){
	#virtual-keyboard-target_keyboard {
    top: 280px!important;
}
}

.infomation-area{
	text-align: center;
}


.base-area .sns-area .google {
     	padding: 10px 17px 10px 16px!important;
		img{
			  margin: 0px 7px -7px 0!important;
		}

}
.base-area .sns-area .facebook {
    	padding: 10px 9px 8px 3px!important;
		img{
			 margin: 0 10px -6px 0!important;
		}
 }

.checkbox-area,.optIn{
	span{
		font-size: 12px!important;
	}
	
}
a.top{
	height: 35px!important;
}
h3.h3 {
	text-align: center;
}
.base-area .element-area {
margin:0px auto!important;
}
#btnArea{
	display: flex!important;
    align-items: center!important;
    justify-content: center!important;
}

table.resultSqlTable {
	display: block;
}

table.resultSqlTable th {
	padding: 8px;
	text-align: left;
	vertical-align: top;
	color: #333;
	background-color: #eee;
	border: 1px solid #b9b9b9;
	white-space: nowrap;
  font-size: 14px;
  min-width: 100px;
}

table.resultSqlTable td {
	padding: 3px;
	background-color: #fff;
	border: 1px solid #b9b9b9;
	white-space: nowrap;
	font-size: 14px;
}

table.resultSqlTable .different {
		background-color: pink;
}

.error{
	color: red;
	text-align: center;
}

ul.ul-disc, ul.ul-disc > li {
  list-style-type: disc;
  margin-left: 1em;
}

.scrollbox{
  margin: 20px;
  height: 690px;
  overflow-y: auto;
}
